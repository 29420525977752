import request from '@/utils/request'

export function getInfolist(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getInfolist.json",
    method: 'post',
    data: formFile,
  })
}

export function getInfoIndex(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getInfoIndex.json",
    method: 'post',
    data: formFile,
  })
}

export function getInfolistDetail(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getInfolistDetail.json",
    method: 'post',
    data: formFile,
  })
}

export function getWarningCustomer(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getWarningCustomer.json",
    method: 'post',
    data: formFile,
  })
}

export function getWarningCustomerYear(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getWarningCustomerYear.json",
    method: 'post',
    data: formFile,
  })
}

export function getChaoENum(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getChaoENum.json",
    method: 'post',
    data: formFile,
  })
}

export function getChaoEDetail(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getChaoEDetail.json",
    method: 'post',
    data: formFile,
  })
}

export function getChaoEWarningNum(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getChaoEWarningNum.json",
    method: 'post',
    data: formFile,
  })
}

export function getChaoEWarningDetail(formFile) {
  return request({
    url: "/api/ajax/warningPanel/getChaoEWarningDetail.json",
    method: 'post',
    data: formFile,
  })
}





