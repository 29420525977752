<template>
  <div class="warning-customer" style="background:white">
    <van-notice-bar :scrollable="true">
      以下显示的客户规则为：截止查看时间有应收余额，且当前月并未回款。（显示时间戳为：{{ time }} ~ {{ currentTime }}）
    </van-notice-bar>
    <div v-if="tableData">
      <el-table
          :data="tableData"
          :show-header="true"
          style="width: 100%"
          :row-class-name="tableRowClassName"
      >
        <el-table-column
            prop="customerName"
            label="客户"
        >
        </el-table-column>
        <el-table-column
            prop="remainRcvb"
            label="应收总余额(元)"
        >
        </el-table-column>
        <!--        <el-table-column-->
        <!--            prop="thisRe"-->
        <!--            label="本月应收"-->
        <!--        >-->
        <!--        </el-table-column>-->
        <el-table-column
            prop="busgrpName"
            label="业务组办"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import {getWarningCustomer} from '@/api/warningPanel'
import {getWarningCustomerYear} from '@/api/warningPanel'

export default {
  name: "WarningCustomer",
  components: {},
  data() {
    return {
      tableData: undefined,
      time: '',
      currentTime: ''
    }
  },
  mounted() {
    document.title = '客户风险详情'
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getWarningCustomer({}).then(res => {
        this.tableData = res
        getWarningCustomerYear({}).then(res1 => {
          this.time = res1[0].time
          let currentTime = new Date();
          let year = currentTime.getFullYear();
          let month = currentTime.getMonth() + 1;
          let day = currentTime.getDate();
          this.currentTime = year + '/' + month + '/' + day
        })
        Toast.success({
          message: '加载成功',
          duration: 800,
        });
      })

    },

    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 0) {
        return 'success-row';
      } else {
        // return 'success-row';
      }
      return '';
    }

  }
}
</script>

<style>
.warning-customer .warning-row {
  background: oldlace;
}

.warning-customer .success-row {
  background: #f0f9eb;
}
</style>